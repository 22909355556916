<template>
    <div class="container">
        <div class="nav">
            <Navgation></Navgation>
        </div>
        <div class="content">
            <Main></Main>
        </div>
    </div>
</template>

<script>
import Navgation from './components/Navgation.vue'
import Main from './components/Main.vue'
export default {
    name: 'Index',
    components: {
        Navgation,
        Main
    },

    data() {
        return {

        }
    },
    methods: {
    },
    mounted() {
    }
}
</script>

<style scoped lang="less"> 
@import '../style/variable.less';
.container{
    width: 100%;
    height: 100%;
    display: flex;

    .nav{
        width: 16vw;
        min-width: 200px;
        height: 100vh;
        background-color: @nav-bg;
        color: @font-color-light;

    }

    .content{
        width: 84vw;
        height: 100vh;
        background-color: @content-bg;
        color: @font-color-light;
    }
}
</style>