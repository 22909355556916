<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'iconfont';
  src: url('./style/iconfont/iconfont.woff2?t=1683441701803') format('woff2'),
       url('./style/iconfont/iconfont.woff?t=1683441701803') format('woff'),
       url('./style/iconfont/iconfont.ttf?t=1683441701803') format('truetype'),
       url('./style/iconfont/iconfont.svg?t=1683441701803#iconfont') format('svg');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
