// router
import Vue from 'vue'
import Router from 'vue-router'
import layout from '@/layout/Index.vue'

Vue.use(Router)
// 避免重复点击路由报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export const constantRoutes = [
    {
        path: '/',
        name: 'layout',
        component: layout,
        children: [
            {
                path: '/',
                name: 'home',
                meta: {
                    title: '首页'
                },
                component: () => import('@/views/Home.vue')
            },
            {
                path: '/map',
                name: 'map',
                meta: {
                    title: '商家地图'
                },
                component: () => import('@/views/Map.vue')
            },
            {
                path: '/memory',
                name: 'memory',
                meta: {
                    title: '活动管理'
                },
                component: () => import('@/views/Memory.vue')
            },
            {
                path: '/users',
                name: 'manager',
                meta: {
                    title: '用户管理'
                },
                component: () => import('@/views/Manager.vue')
            },
            {
                path: '/banner',
                name: 'banner',
                meta: {
                    title: '商品管理'
                },
                component: () => import('@/views/Banner.vue')
            },
            {
                path: '/goods',
                name: 'goods',
                meta: {
                    title: '商家管理'
                },
                component: () => import('@/views/Goods.vue')
            },
            {
                path: '/information',
                name: 'information',
                meta: {
                    title: '资讯文章'
                },
                component: () => import('@/views/Information.vue')
            },{
                path: '/guide',
                name: 'guide',
                meta: {
                    title: '指南管理'
                },
                component: () => import('@/views/guide/Index.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/editArticle',
        name: 'editArticle',
        component: () => import('@/views/EditArticle.vue')
    },{
        path: '/guide/edit',
        name: 'guideEdit',
        component: () => import('@/views/guide/GuideEdit.vue')
    }
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: constantRoutes
})


// cookie中是否有token，有则跳转到首页，没有则跳转到登录页

router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        const token = localStorage.getItem('token')
        if (!token) {
            next('/login')
        } else {
            next()
        }
    }
})

export default router