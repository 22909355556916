import Vue from 'vue'
import App from './App.vue'
import router from './router';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import '@/style/theme/index.css';

Vue.config.productionTip = false
Vue.use(ElementUI,{size:"small"});
Vue.use(VueRouter);
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
