<template>
    <div class="Header">
        <div class="header-left">
            <div class="logo">
                <img src="../../assets/logo.png" alt="">
            </div>
            <div class="title">
                <h1>{{ routeName }}</h1>
            </div>
        </div>
        <div class="header-right">
            <div class="user">
                <span class="iconfont icon-yonghu"></span>
                <span>admin</span>
            </div>
            <div class="logout">
                <span class="iconfont icon-tuichu"></span>
                <span style="cursor: pointer;" @click="logout">退出</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Header',
        data() {
            return {
            }   
        },
        computed: {
            routeName(){
                return this.$route.meta.title;
            }
        },
        methods: {
            logout(){
                this.$router.push('/login');
                localStorage.setItem('token', null);
                this.$message({
                    message: '退出成功',
                    type: 'success'
                });
            }
        }
    }
</script>
<style scoped lang="less">
@import '../../style/variable.less';
.Header{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: @nav-bg;
    color: @font-color-light;
    .header-left{
        display: flex;
        align-items: center;
        .logo{
            width:50px;
            height: 50px;
            margin-left: 2vw;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .title{
            margin-left: 1vw;
            h1{
                font-size: 1.5vw;
            }
        }
    }
    .header-right{
        display: flex;
        align-items: center;
        margin-right: 2vw;
        .user{
            margin-right: 1vw;
            .iconfont{
                font-size: 2vw;
            }
        }
        .logout{
            .iconfont{
                font-size: 2vw;
            }
        }
    }
}

</style>
