<template>
    <div>
        <div class="h">
            <Header></Header>
        </div>
        <div class="m">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>

import Header from './Header.vue'
export default {
    name: 'Main',
    components: {
        Header
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
<style scoped lang="less">
@import '../../style/variable.less';
.m{
    width: 100%;
    height: 90vh;
    background-color: #fff;
    color: @font-color-dark;
}
</style>