<template>
    <div class="nav">
        <div class="logo">绘长沙·咖啡地图  |  后台</div>
        <div class="item">
            <ul>
                <li @click="go('home')" :class="{active:active=='home'}"> <i class="iconfont">&#xe661;</i> 首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页</li>
                <li @click="go('map')" :class="{active:active=='map'}"><i class="iconfont">&#xe884;</i> 商家地图</li>
                <li @click="go('banner')" :class="{active:active=='banner'}"> <i class="iconfont">&#xe600;</i> 活动管理</li>
                <li @click="go('manager')" :class="{active:active=='manager'}"> <i class="iconfont">&#xe601;</i> 用户管理</li>
                <li @click="go('goods')" :class="{active:active=='goods'}"> <i class="iconfont">&#xe631;</i> 商品管理</li>
                <li @click="go('memory')" :class="{active:active=='memory'}"> <i class="iconfont">&#xeb67;</i> 商家管理</li>
                <li @click="go('information')" :class="{active:active=='information'}"> <i class="iconfont">&#xe8a7;</i> 资讯文章</li>
                <li @click="go('guide')" :class="{active:active=='guide'}"> <i class="iconfont">&#xe8a7;</i> 指南管理</li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Navgation',
        data() {
            return {
                active: 'home'
            }
        },
        methods: {
            go(n){
                this.$router.push({name:n})
            }
        },
        mounted() {
            this.active = this.$route.name
            if (this.$route.path == '/') {
                this.active = 'home'
            }
        },
        watch: {
            $route(to){
                this.active = to.name
            }
        }
    }
</script>

<style scoped lang="less">
@import '../../style/variable.less';
.nav{
    padding: 4px;
    box-sizing: border-box;
}
.logo{

    font-size: 20px;
    color: #fff;
    text-align: center;
    padding: 20px 10px;
}

ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
li{
    padding: 14px 30px;
    color: #fff;
    cursor: pointer;
    text-align: left;
    background-color: #ffffff11;
    border-radius: 4px;
    /** 字体间距 */
    letter-spacing: 2px;
    transition-duration: .3s;
    &:hover{
        background-color: #ffffff22;
        color: @font-color-theme-hover;
        transition-duration: .3s;
    }
}
.active{
    background-color: #ffffff22;
    color: @font-color-theme;
    transition-duration: .3s;
}
.item{
    margin-top: 20px;
}
</style>